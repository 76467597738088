import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";

import "@fhmuenster/vue-components/dist/fh-styles.min.css";
import "@fhmuenster/vue-components/dist/style.css";

import { createI18n } from "@fhmuenster/vue-components";

const i18n = createI18n();

createApp(App).use(router).use(i18n).mount("#app");
