import { createRouter, createWebHistory } from "vue-router";

// import { allLocales } from "@fhmuenster/vue-components";

// import App from "./App.vue";

// empty router definition, just needed to create the web history
export default createRouter({
  history: createWebHistory(),
  routes: [
    // ...allLocales.map((locale) => {
    //   return {
    //     path: `/${locale}`,
    //     redirect: `/${locale}/`,
    //   };
    // }),
    // ...allLocales.map((locale) => {
    //   return {
    //     path: `/${locale}/:routePath(.*)`,
    //     component: App,
    //   };
    // }),
  ],
});
