<template>
  <FhApp :requestPath="requestPath" />
</template>

<script>
import { locale, allLocales } from "@fhmuenster/vue-components";
import { FhApp } from "@fhmuenster/vue-components";

export default {
  components: {
    FhApp,
  },
  data() {
    return {
      requestPath: null,
      myLocale: locale,
    };
  },
  watch: {
    myLocale: function () {
      this.$router.push({
        path: `/${locale.value}${this.removeLanguageCodeFromURL(
          this.$route.path,
          allLocales,
        )}`,
        query: this.$route.query,
      });
    },
    $route(to) {
      if (this.startsWithLanguageCode(to.path, allLocales)) {
        const currentRouteLanguage = this.getCurrentLanguage(
          to.path,
          allLocales,
        );
        if (currentRouteLanguage != this.myLocale) {
          this.myLocale = currentRouteLanguage;
        }
      } else {
        this.$router.replace({ path: `/${this.myLocale}${to.path}` });
      }

      const urlWithoutLanguageCode = this.removeLanguageCodeFromURL(
        to.path,
        allLocales,
      );

      if (urlWithoutLanguageCode === "/") {
        this.requestPath = process.env.VUE_APP_CMS_URL + urlWithoutLanguageCode;
      } else if (urlWithoutLanguageCode.endsWith("/")) {
        // remove "/" on end of url
        this.requestPath =
          process.env.VUE_APP_CMS_URL + urlWithoutLanguageCode.slice(0, -1);
      } else {
        this.requestPath = process.env.VUE_APP_CMS_URL + urlWithoutLanguageCode;
      }

      // Add local get param for deppl translations
      this.requestPath += `?loc=${locale.value}`;

      document.documentElement.setAttribute("lang", locale.value);
    },
  },
  methods: {
    startsWithLanguageCode: function (url, allLocales) {
      return allLocales.some((locale) => url.startsWith(`/${locale}/`));
    },
    removeLanguageCodeFromURL: function (url, allLocales) {
      const matchingLocale = allLocales.find((locale) =>
        url.startsWith(`/${locale}/`),
      );

      if (matchingLocale) {
        return url.replace(`/${matchingLocale}/`, "/");
      }

      return url;
    },
    getCurrentLanguage: function (url, allLocales) {
      const matchingLocale = allLocales.find((locale) =>
        url.startsWith(`/${locale}/`),
      );

      return matchingLocale || false;
    },
  },
};
</script>
